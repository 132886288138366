<template>
  <!-- 产品三级菜单 -->
  <div class="microbial">
    <!-- <div @click="back()" class="back">
      <van-icon style="font-weight: bold" size="13" name="arrow-left" />
      <span>返回</span>
    </div> -->
    <!-- 头部 -->
    <swipeTop :top_img="top_img" :ZHtitle="ZHtitle" :ENtitle="ENtitle">
    </swipeTop>
    <!-- 搜索框 -->
    <seachVue @searchName="searchName"></seachVue>
    <!-- 标签 -->
    <!-- <tag></tag> -->
    <classifyList2Vue
      :checkindex="checkindex"
      :toptype="toptype"
    ></classifyList2Vue>
    <!-- 开篇文字 -->
    <div v-if="toptext" class="OpeningWords">
      <span>{{ toptext }}</span>
    </div>
    <!-- 产品列表 -->
    <div class="listBox" :style="toptext ? 'height:38.9%' : 'height:53.9%'">
      <listVue
        @todetaill="todetaill"
        :listTitle="listTitle"
        :projuctList="productList"
        :labelList="moduleList"
        @chooseMou="chooseMou"
      ></listVue>
    </div>
  </div>
</template>
<script>
import swipeTop from "@/components/swipeTop.vue";
import seachVue from "@/components/seachVue.vue";
import tag from "@/components/tag";
import listVue from "@/components/listVue.vue";
import classifyList from "@/components/classifyList.vue";
import classifyList2Vue from "../../components/classifyList2.vue";
import { genMenuInfo } from "@/api/path";
export default {
  name: "microbial",
  components: {
    swipeTop,
    listVue,
    seachVue,
    classifyList2Vue,
    tag,
  },
  data() {
    return {
      top_img: "", // 头部背景图片
      listTitle: "",
      ZHtitle: "", // 中文标题
      ENtitle: "", // 英文标题
      moduleList: [], // 分类标题
      productList: [], // 列表数据
      correlationId: 0,
      checkindex: Number(this.$route.query.checkindex),
      menuId: this.$route.query.menuId,
      toptext: "",
      toptype: [],
      bgcolor: "",
      maxparentId: "", //拿到导航栏的父级id
    };
  },
  created() {
    this.getAllMenu();
  },
  watch: {
    $route: function (newQuestion, oldQuestion) {
      this.menuId = newQuestion.query.mid;
      this.checkindex = Number(this.$route.query.checkindex);
      this.getAllMenu();
    },
  },
  methods: {
    // 返回
    back() {
      this.$router.go(-1);
    },
    // 获取搜索返回内容
    searchName(res) {
      console.log("搜素框返回内容：", res);
    },
    // 分类选择切换
    chooseMou(item, index) {
      let params = {
        mid: item.menuId,
      };
      genMenuInfo(item.menuId, params).then((res) => {
        this.productList = res.data.productList;
      });
      this.currentSort = index;
    },
    // 获取初始数据
    getAllMenu() {
      genMenuInfo(this.menuId)
        .then((res) => {
          this.correlationId = res.data.correlationId; // 顶层id
          this.ZHtitle = res.data.menuName; // 获取中文标题
          this.top_img = res.data.background; // 获取头部背景图片
          this.ENtitle = res.data.menuEnglish; // 获取英文标题
          this.toptext = res.data.content; // 获取开篇文字
          this.toptype = res.data.classificationMessageAlways; // 获取导航标签
          // this.maxparentId = res.data.classificationMessageAlways[0].parentId;
          if (this.maxparentId == 5) {
            this.bgcolor = "#00078c";
            this.listTitle = "活动信息";
          }
          this.moduleList = res.data.subMenuList; // 获取分类
          // 判断是否有分类
          if (this.moduleList == null) {
            // 获取分类下内容
            this.productList = res.data.productList;
          } else {
            this.productList = res.data.subMenuList[0].productList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 详情页
    todetaill(item) {
      if (item.fileType === 4) {
        location.href = item.fileUrl;
      } else {
        this.$router.push({
          path: "/detailsPage",
          query: { menuid: item.productId },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.microbial {
  background-color: #182389;
  height: 100vh;
  scrollbar-width: none;
}
.back {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3vw;
  border-radius: 50%;
  padding: 8px 10px;
  color: #182389;
  font-weight: bold;
  span {
    transform: scale(0.9);
  }
}
.listBox {
  height: 38.9%;
}
.OpeningWords {
  background-color: #bf545a;
  color: #fff;
  font-size: 3.2vw;
  line-height: 40px;
  height: 15%;
  /*overflow: hidden;*/
  padding: 20px 20px;
  letter-spacing: 3px;
  text-indent: 58px;
  overflow: auto;
}
.retrieve {
  background-color: #fff;
}
/deep/.van-search__content {
  background-color: #f7f8fa !important;
}
/deep/ .listTitle {
  span {
    color: #fff;
  }
}
.tag {
  background-color: #fff;
}
</style>
